export type DetailSpec = {
  title: string;
  disclaimer?: string;
  bold: boolean;
  essential?: string[];
  deluxe?: string[];
  premium?: string[];
  children?: DetailSpec[];
};

const wholeHouseChildren: DetailSpec[] = [
  {
    title: "Flooring",
    bold: true,
    children: [
      {
        title: "Common Areas & Bedrooms",
        bold: false,
        essential: ["Daltile Level 3 wood-look tile Porcelain"],
        deluxe: ["Marazzi wood-look tile Porcelain"],
        premium: ["Daltile Level 5 wood-look tile Porcelain"],
      },
      {
        title: "Bathrooms & Laundry",
        bold: false,
        essential: ["Level 1 tile, Ceramic"],
        deluxe: ["Level 2 tile, Porcelain"],
        premium: ["Level 3 tile, Porcelain"],
      },
    ],
  },
  {
    title: "Countertops",
    bold: true,
    essential: ["Silestone quartz - Level 1"],
    deluxe: ["Dekton quartz with veining - Level 2"],
    premium: ["Silestone quartz with veining - Level 5"],
  },
  {
    title: "Cabinet Hardware",
    bold: true,
    essential: ["Knobs & pulls"],
    deluxe: ["Pulls"],
    premium: ["Designer pulls"],
  },
  {
    title: "Plumbing Fixtures",
    bold: true,
    essential: ["Chrome"],
    deluxe: ["Brushed nickel, matte black, or brushed gold - per design package"],
    premium: ["Matte black or brushed gold - per design package"],
  },
  {
    title: "Light Fixtures",
    bold: true,
    essential: ["Standard (Progress & more)"],
    deluxe: ["Upgraded (Millennium & more)"],
    premium: ["Designer (Kichler & more)"],
  },
  {
    title: "Interior Trim",
    bold: true,
    essential: ["Sheetrock return window casings"],
    deluxe: ["Sheetrock return window casings"],
    premium: ["Painted finger-jointed pine decorative window casings"],
  },
];

const kitchenChildren: DetailSpec[] = [
  {
    title: "Kitchen Appliances",
    bold: true,
    children: [
      {
        title: "Brand",
        bold: false,
        essential: ["Whirlpool ", "(The Finn only: JennAir and Whirlpool)"],
        deluxe: ["Jenn Air or KitchenAid"],
        premium: ["Jenn Air or KitchenAid"],
      },
      {
        title: "Refrigerator / Freezer",
        bold: false,
        essential: ["36” counter-depth"],
        deluxe: ["36” counter-depth"],
        premium: ["36” counter-depth"],
      },
      {
        title: "Range",
        bold: false,
        essential: ['30" stainless steel electric range'],
        deluxe: ['30" stainless steel electric range'],
        premium: ['36" black & stainless steel electric cooktop'],
      },
      {
        title: "Wall Oven",
        bold: false,
        essential: ["-"],
        deluxe: ["-"],
        premium: ['27" wall oven/microwave combo may be included - per plan'],
      },
      {
        title: "Microwave",
        bold: false,
        essential: ["1.1 cu microwave hood combo ", "(The Finn only: Built-in microwave)"],
        deluxe: ['24" wide under-counter microwave drawer'],
        premium: ["Microwave drawer or wall oven/microwave combo - per plan"],
      },
      {
        title: "Dishwasher",
        bold: false,
        essential: ['24" dishwasher'],
        deluxe: ['24" dishwasher'],
        premium: ['24" dishwasher'],
      },
      {
        title: "Hood",
        bold: false,
        essential: ["Microwave hood combo ", "(The Finn only: Stainless steel chimney hood)"],
        deluxe: ['30" wall mount canopy hood'],
        premium: ['36" stainless steel hood liner with custom cabinet hood surround'],
      },
    ],
  },
  {
    title: "Kitchen Cabinetry",
    disclaimer: "Brand and details subject to change pending supply chain",
    bold: true,
    essential: [
      "Merillat Basics",
      '36" lowers, 36" uppers',
      '1/2" thick engineered wood drawer fronts',
      "Partial overlay",
      "Single cabinet color",
    ],
    deluxe: [
      "Merillat Masterpiece",
      '36" lowers, 42" uppers',
      "Solid hardwood drawer fronts & plywood construction",
      "Full overlay",
      "Perimeter cabinet color with accent island",
    ],
    premium: [
      "Merillat Masterpiece",
      '36" lowers, 42" uppers + stacking cabinets',
      "Solid hardwood drawer fronts & plywood construction",
      "Full overlay",
      "Perimeter cabinet color with accent island",
    ],
  },
  {
    title: "Kitchen Countertop",
    bold: true,
    children: [
      {
        title: "Kitchen Backsplash",
        bold: true,
        essential: ['3" x 6" decorative tile'],
        deluxe: ['4" x 12" decorative tile'],
        premium: ["Quartz slab"],
      },
    ],
  },
];

const bathroomChildren: DetailSpec[] = [
  {
    title: "Bathroom Mirrors",
    bold: true,
    children: [
      {
        title: "Primary & Powder",
        bold: false,
        essential: ["Frameless"],
        deluxe: ["Framed"],
        premium: ["Framed"],
      },
      {
        title: "Secondary Bathrooms",
        bold: false,
        essential: ["Frameless"],
        deluxe: ["Frameless"],
        premium: ["Framed"],
      },
    ],
  },
  {
    title: "Secondary Tub / Shower Enclosures",
    bold: true,
    children: [
      {
        title: "Tubs",
        bold: false,
        essential: ["Curtain rod"],
        deluxe: ["Curtain rod"],
        premium: ["Frameless clear glass enclosure with sliding door"],
      },
      {
        title: "Showers",
        bold: false,
        essential: ["Frameless clear glass enclosure"],
        deluxe: ["Frameless clear glass enclosure"],
        premium: ["Frameless clear glass enclosure"],
      },
    ],
  },
];

export const SpecDetails: DetailSpec[] = [
  { title: "Whole House", bold: true, children: wholeHouseChildren },
  { title: "Kitchen", bold: true, children: kitchenChildren },
  { title: "Bathrooms", bold: true, children: bathroomChildren },
];

export type standardSpec = {
  title: string;
  details: string[] | standardSpec[];
};

export const homeboundStandard = [
  {
    title: "Quality & Livability",
    details: [
      {
        title: "High Ceilings and airiness",
        details: [
          "1-story plans: 10' ceilings",
          "2-story plans: 10' ceilings on 1st floor, 9' ceilings on 2nd floor (The Finn: 10' ceilings on 2nd floor)",
          "8' interior doors",
        ],
      },
      "Sound insulation: Solid core doors",
      "Resiliency: Vinyl impact-rated windows, cementitious siding",
      {
        title: "Safety:",
        details: ["Alarm.com video doorbell", "Kwikset Smart Code Electronic deadbolt and handle set"],
      },
      {
        title: "Built for living",
        details: [
          "Dimmable lights in great room and primary bedroom",
          "Programmable smart thermostat",
          "Liftmaster Wifi Belt-Drive Garage Door Opener (if garage option selected)",
        ],
      },
    ],
  },
  {
    title: "Energy & Sustainability",
    details: [
      "Energy Star Certified Home",
      "Open cell foam insulation in exterior walls adjoining conditioned space",
      "Open-cell foam insulation applied to the underside of roof decking in areas over conditioned space",
      "R-13 BATT insulation in raised floor system conditions",
      "High-efficiency HVAC System",
      "Electric tankless water heater",
      "Pre-wired 50A circuit for future electric vehicle charger installation",
    ],
  },
  {
    title: "Kitchen",
    details: [
      {
        title: "High-quality cabinet features",
        details: ["Undercabinet lighting", "Refrigerator cabinet", "Drawer bank", "Crown moulding", "Trash pullout"],
      },
      "Quartz countertops",
      "Backsplash to bottom of upper cabinets and hood",
    ],
  },
  {
    title: "Laundry Appliances",
    details: ["Included - Whirlpool, stackable in some plans"],
  },
  {
    title: "Bathroom",
    details: [
      "Moen plumbing fixtures",
      "Quartz countertops",
      "Primary baths with shower head + handheld",
      "Primary shower with frameless glass enclosure",
      "Freestanding tub in primary bathroom per plan (not included in all)",
      "Tiled shower floors and shower surrounds run to ceiling",
      "Built-in alcove tubs in secondary bathrooms",
      "Shower niches per plan (not included in all showers)",
      '36" standard counter height vanity cabinets',
      "Elongated Comfort Height toilets",
    ],
  },
  {
    title: "Lighting",
    details: [
      "Disk lights with Energy Star-certified ceiling fan in great room and primary bedroom",
      "Disk lights with ceiling fan prewire in secondary bedrooms",
      "Wildlife-safe exterior sconces with Z-Wave switch",
      "Pre-wire for ceiling fans on front and rear porch/deck (option to add fans)",
    ],
  },
  {
    title: "Interior Stairs",
    details: ["White risers, treads stained to coordinate with main flooring (if interior stairs included in plan)"],
  },
  {
    title: "Interior Walls & Trim",
    details: [
      "Skip trowel texture drywall",
      "Square 1x6 finger-jointed pine painted baseboard",
      "Square 1x4 finger-jointed pine painted door casing",
    ],
  },
  {
    title: "Landscaping",
    details: ["Standard delivery is final grading"],
  },
];
